<template>
  <v-row>
    <v-autocomplete
      :label="label"
      :items="items"
      v-model="modelInput"
      clearable
      style="width: 150px;"
      class="pl-3 pr-3 mb-3"
      no-data-text="Cargando datos..."
      :rules="[requerido]"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      {{estado}}
    </v-btn>
    <slot></slot>
    <v-overlay :value="loading" :z-index="9999">
      <v-progress-circular
        indeterminate
        :size="100"
        color="primary"
        :width="10"
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  data: () => ({
    estado: 'NEW',
    loading: false,
    requerido: v => !!v || 'Este campo es obligatorio'
  }),
  mixins: [
    Globales
  ],
  props: ['items', 'store', 'metodo', 'model', 'api_carga', 'dialog', 'label', 'modo', 'nnuevo', 'filtrar', 'api_filtro'],
  methods: {
    async cargarLista () {
      this.loading = true
      this.cargarTabla(this.api_cargaInput, this.storeInput, this.metodoInput)
      this.loading = false
    },
    async cargarListaFiltro () {
      this.loading = true
      this.$store.commit(this.storeInput, [])
      const response = await ApiCon.get2(this.api_filtroInput, this.$session.get('usuario'), this.modelInput)
      if (response.status === 200) {
        this.$store.commit(this.storeInput, response.data)
      } else { alert('error ' + this.metodoInput) }
      this.loading = false
    },
    cargarNuevoEditar () {
      if (this.modelInput === '' | this.modelInput === null) {
        this.modoInput = this.nnuevoInput
      } else {
        this.modoInput = 'EDITAR'
      }
      this.dialogInput = true
    },
    cambioTexto () {
      if (this.modelInput === '' | this.modelInput === null) {
        this.estado = 'NEW'
      } else {
        this.estado = 'EDIT'
      }
    },
    cargarDatos () {
      this.cargarLista()
    }
  },
  created () {
    this.cargarDatos()
  },
  computed: {
    itemsInput: {
      get: function () {
        return this.items
      },
      set: function (newValue) {
        this.$emit('update:items', newValue)
      }
    },
    storeInput: {
      get: function () {
        return this.store
      },
      set: function (newValue) {
        this.$emit('update:store', newValue)
      }
    },
    metodoInput: {
      get: function () {
        return this.metodo
      },
      set: function (newValue) {
        this.$emit('update:metodo', newValue)
      }
    },
    modelInput: {
      get: function () {
        return this.model
      },
      set: function (newValue) {
        this.$emit('update:model', newValue)
      }
    },
    api_cargaInput: {
      get: function () {
        return this.api_carga
      },
      set: function (newValue) {
        this.$emit('update:api_carga', newValue)
      }
    },
    dialogInput: {
      get: function () {
        return this.dialog
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    labelInput: {
      get: function () {
        return this.label
      },
      set: function (newValue) {
        this.$emit('update:label', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    },
    nnuevoInput: {
      get: function () {
        return this.nnuevo
      },
      set: function (newValue) {
        this.$emit('update:nnuevo', newValue)
      }
    },
    filtrarInput: {
      get: function () {
        return this.filtrar
      },
      set: function (newValue) {
        this.$emit('update:filtrar', newValue)
      }
    },
    api_filtroInput: {
      get: function () {
        return this.api_filtro
      },
      set: function (newValue) {
        this.$emit('update:api_filtro', newValue)
      }
    }
  },
  watch: {
    model () {
      this.cambioTexto()
    },
    filtrar () {
      if (this.filtrar === true) {
        this.cargarListaFiltro()
      } else {
        this.cargarLista()
      }
    }
  }
}
</script>
